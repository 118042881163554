import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'
import { useInView } from 'react-intersection-observer'

const MvCopyImg = () => {
  const data = useStaticQuery(
    graphql`
      {
        allFile(filter: { name: { regex: "/top_mv_heading_/" } }, sort: { fields: name, order: ASC }) {
          edges {
            node {
              id
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    `
  )
  const images = data.allFile.edges

  const { ref, inView } = useInView({
    rootMargin: '0px',
    triggerOnce: true,
  })

  function getRandomInt(min, max) {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min) + min)
  }

  const alt = ['人', '智', 'を', '超', 'え', 'た', '"', '感', '動', '"', 'を', '未', '来', 'へ', '。']
  let animateWords = []
  images.map((img, index) => (animateWords = [...animateWords, { character: img, delay: getRandomInt(5, 750), alt: alt[index] }]))

  return (
    <>
      {animateWords.map(({ character, delay, alt }, index) => (
        <span key={`${index}-${character}`} ref={ref} style={{ transition: 'ease-in-out', transitionDelay: `${delay}ms`, transitionDuration: '750ms', opacity: inView ? '1' : '0' }} className="max-h-[20px]">
          <GatsbyImage image={character.node.childImageSharp.gatsbyImageData} alt={alt} objectFit="contain" />
        </span>
      ))}
    </>
  )
}
export default MvCopyImg
