import React from 'react'
import { thoughtSection, thoughtChild } from '../../styles/top.module.scss'
import HeadingInview from '../module/headingInview'

const ThoughtSection = ({ toppage }) => {
  return (
    <section className={thoughtSection}>
      <h2 className={thoughtChild + ' heading'}>
        <HeadingInview text={toppage.title} />
      </h2>
      <div
        className={thoughtChild}
        dangerouslySetInnerHTML={{
          __html: toppage.content.childMarkdownRemark.html.replace(/\n/g, '<br />'),
        }}
      />
    </section>
  )
}

export default ThoughtSection
