import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import DefaultImg from '../module/defaultImg'
import * as s from '../../styles/top.module.scss'
import PageHeadline from '../pageHeadline'

const TopNewsSection = ({ news }) => {
  return (
    <section className={s.journalSection}>
      <PageHeadline text="news" />
      <div className="columnComponent">
        {news.edges.slice(0, 3).map(({ node: { id, slug, featuredImage, title, content, publishDate, createdAt } }) => (
          <article className={s.article + ' columnChild'} key={id}>
            <Link to={`/news/${slug}/`}>
              <div className={s.thumbnail}>{featuredImage ? <GatsbyImage image={featuredImage.gatsbyImageData} objectFit="cover" alt="LettersGridTopC image" /> : <DefaultImg />}</div>
              <h3 className="columnHeading">{title}</h3>
              <div className="columnExcerpt">{content?.childMarkdownRemark.excerpt}</div>
              <time className="columnTime" dateTime={publishDate ?? createdAt}>
                {publishDate ?? createdAt}
              </time>
            </Link>
          </article>
        ))}
      </div>
      <div className={s.moreLink}>
        <Link to="/news">View All</Link>
      </div>
    </section>
  )
}

export default TopNewsSection
