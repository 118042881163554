import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import * as s from '../../styles/top.module.scss'
import MvCopyImg from './myCopyImg'

const MainVisual = () => {
  const data = useStaticQuery(
    graphql`
      query {
        contentfulMainVisual {
          fvVideo
        }
      }
    `
  )
  const video = data.contentfulMainVisual.fvVideo
  return (
    <div className={s.mainVisual}>
      <h2 className={s.mvHeading}>
        <span className={s.mvCopyHeading}>
          <MvCopyImg />
        </span>
      </h2>
      <div className={s.video_background}>
        <div className={s.video_foreground}>
          <iframe src={`${video}&autoplay=1&loop=1&muted=1&background=1`} width="640" height="360" placeholder="none" allow="autoplay; fullscreen" title="vimeo-player"></iframe>
        </div>
      </div>
    </div>
  )
}

export default MainVisual
