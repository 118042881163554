import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '~/components/seo'
import MainVisual from '~/components/pages/mainvisual'
import TopNewsSection from '~/components/pages/topNewsSection'
import ThoughtSection from '~/components/pages/thought'

const IndexPage = ({ data: { toppage, news } }) => {
  return (
    <Layout>
      <Seo title="株式会社アンティークコインギャラリア" pageUrl="/" />
      <MainVisual />
      <ThoughtSection toppage={toppage} />
      <TopNewsSection news={news} />
    </Layout>
  )
}
export default IndexPage

export const query = graphql`
  query {
    toppage: contentfulPage(slug: { eq: "top" }) {
      id
      title
      slug
      content {
        childMarkdownRemark {
          html
        }
      }
    }
    news: allContentfulNews(sort: { fields: publishDate, order: DESC }) {
      edges {
        node {
          id
          slug
          title
          publishDate(formatString: "YYYY.MM.DD")
          featuredImage {
            gatsbyImageData
          }
          createdAt(formatString: "YYYY.MM.DD")
          content {
            childMarkdownRemark {
              html
              excerpt(pruneLength: 100, truncate: true)
            }
          }
        }
      }
    }
  }
`
