// extracted by mini-css-extract-plugin
export var article = "top-module--article--3lJ5f";
export var columnExcerpt = "top-module--columnExcerpt----oHU";
export var inviewLetter = "top-module--inviewLetter--W7Kqm";
export var journalSection = "top-module--journalSection--uqLLr";
export var loadingArea = "top-module--loadingArea--9ghhZ";
export var loadingAreaWrap = "top-module--loadingAreaWrap--9l-Zi";
export var mainVisual = "top-module--mainVisual--hO9Z5";
export var moreLink = "top-module--moreLink--LdtwU";
export var mvCopyHeading = "top-module--mvCopyHeading--iHWQB";
export var mvHeading = "top-module--mvHeading--Itf38";
export var randomImgWrap = "top-module--randomImgWrap--JN4QM";
export var thoughtChild = "top-module--thoughtChild--6nLic";
export var thoughtSection = "top-module--thoughtSection--AAqHS";
export var thumbnail = "top-module--thumbnail--Yr2OD";
export var video_background = "top-module--video_background--I6ImY";
export var video_foreground = "top-module--video_foreground--FLVAR";