import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { GatsbyImage } from 'gatsby-plugin-image'

const DefaultImg = () => {
  const { file } = useStaticQuery(
    graphql`
      {
        file(relativePath: { eq: "ogp.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
      }
    `
  )
  return <GatsbyImage image={file.childImageSharp.gatsbyImageData} alt="default image" />
}

export default DefaultImg
